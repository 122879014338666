/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { pageTypes } from './pageTypes';

export const getSeoPageType = (pageType: string, appendedPageType: string): string => {
  switch (pageType) {
    case pageTypes.brandShelfPremium:
      return `PBP:${appendedPageType}`;
    case pageTypes.brandShelf:
      return `BBP:${appendedPageType}`;
    case pageTypes.facetedNav:
      return `FN:${appendedPageType}`;
    case pageTypes.home:
      return 'HOME';
    case pageTypes.landingPage:
      return `LP:/${appendedPageType}`;
    case pageTypes.shopByMake:
      return 'MLP:/shopbymake';
    case pageTypes.shopByModel:
      return 'MMLP:/shopbymodel';
    case pageTypes.pdp:
      return `PD:${appendedPageType}`;
    case pageTypes.catalog:
      return `CAT:${appendedPageType}`;
    case pageTypes.subcategory:
      return `SCAT:${appendedPageType}`;
    case pageTypes.navigation:
      return `NAV:${appendedPageType}`;
    case pageTypes.catalogSearchResults:
      return `ISR:${appendedPageType}`;
    case pageTypes.catalogNewSearchResults:
      return `ISR:${appendedPageType}`;
    case pageTypes.shopByMakeCategory:
      return `M:${appendedPageType}`;
    case pageTypes.shopByModelCategory:
      return `MM:${appendedPageType}`;
    case pageTypes.giftCard:
      return `LP:/landingPage${appendedPageType}`;
    case pageTypes.makeNav:
      return `MNAV:${appendedPageType}`;
    case pageTypes.makeCategory:
      return `MCAT:${appendedPageType}`;
    case pageTypes.makeSubCategory:
      return `MSCAT:${appendedPageType}`;
    case pageTypes.makePartType:
      return `MPT:${appendedPageType}`;
    case pageTypes.modelNav:
      return `MMNAV:${appendedPageType}`;
    case pageTypes.modelCategory:
      return `MMCAT:${appendedPageType}`;
    case pageTypes.modelSubCategory:
      return `MMSCAT:${appendedPageType}`;
    case pageTypes.modelPartType:
      return `MMPT:${appendedPageType}`;
    case pageTypes.yearMakeModelNav:
      return `YMMNAV:${appendedPageType}`;
    case pageTypes.yearMakeModelCategory:
      return `YMMCAT:${appendedPageType}`;
    case pageTypes.yearMakeModelSubCategory:
      return `YMMSCAT:${appendedPageType}`;
    case pageTypes.yearMakeModelPartType:
      return `YMMPT:${appendedPageType}`;
    case pageTypes.productShelf:
      return `PT:${appendedPageType}`;
    case pageTypes.reviews:
      return `PRP:${appendedPageType}`;
    default:
      return 'other';
  }
};
