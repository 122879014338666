/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';
import { useLabel } from '@/hooks/useLabels';
import cx from 'classnames';
import { eventConstants } from '@/constants/event';
import { ButtonBase } from '../ButtonBase/ButtonBase';

type Props = {
  numberOfDots: number;
  currentSlide: number;
  handleDotClick?: (index: number, setFocusOnAnchor: boolean) => void;
};

function CarouselDotsComponent({ numberOfDots, currentSlide, handleDotClick }: Props) {
  const goToSlideLabel = useLabel('label_go_to_slide');

  if (numberOfDots <= 1) {
    return null;
  }
  const onKeyUpHandler = (e: React.KeyboardEvent, index: number) => {
    if (e.key === eventConstants.enterKeyType || e.key === eventConstants.spaceKeyType) {
      handleDotClick?.(index, true);
    }
  };

  const onClickHandler = (e: React.MouseEvent<HTMLElement>, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    handleDotClick?.(index, false);
  };

  const createDots = () => {
    return Array.from(
      {
        length: numberOfDots,
      },
      (_, index) => {
        return (
          <li key={`carousel-button-${index + 1}`} className={styles.carouselDotListItem}>
            <ButtonBase
              className={cx(styles.carouselDot, { [styles.active]: index === currentSlide })}
              aria-label={`${goToSlideLabel} ${index + 1}`}
              onClick={(e: React.MouseEvent<HTMLElement>) => onClickHandler(e, index)}
              onKeyUp={(e: React.KeyboardEvent) => onKeyUpHandler(e, index)}
            />
          </li>
        );
      }
    );
  };

  return (
    <div data-testid="carousel-indicator-container" className={styles.carouselDotsCenter}>
      <ul className={styles.carouselDotsList}>{createDots()}</ul>
    </div>
  );
}

export const CarouselDots = React.memo(CarouselDotsComponent);
