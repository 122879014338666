/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const NEXT = 'NEXT';

export const PREV = 'PREV';

export const MAX_VISIBLE_THUMBNAILS = 6;
