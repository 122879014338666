/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import cx from 'classnames';
import { SwipeEventData } from 'react-swipeable';
import usePrevious from '../../utils/usePrevious';
import ThumbnailComponent from './ThumbnailComponent';
import Image from '@/components/NextImage';
import azCommonStyles from '@/theme/globals.module.scss';
import { LoadingIndicatorDots } from '@/components/LoadingIndicatorDots';
import { CarouselDots } from './CarouselDots';
import type { CarouselProps, Direction, CarouselState, CarouselAction } from './types';
import { NEXT, PREV } from './constants';
import styles from './styles.module.scss';
import { Button } from '@/components/Button';
import { useLabel } from '@/hooks/useLabels';

const sliderControls = {
  leftArrow: {
    src: '/images/arrow-left-grey-shadow-white.svg',
    alt: 'Previous slide',
  },
  rightArrow: {
    src: '/images/arrow-right-grey-shadow-white.svg',
    alt: 'Next slide',
  },
};

const getOrder = (index: number, pos: number, numItems: number) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const initialState: CarouselState = {
  pos: 0,
  sliding: false,
  dir: NEXT,
  updateType: NEXT,
};

const Carousel = (props: CarouselProps) => {
  const {
    children,
    imgTitle,
    items = [],
    selectedThumbnailIndex,
    groupedLayout,
    showAllThumbnails = true,
    verticalLayout,
    classes = {},
    thumbnailClasses = {},
    carouselCaption,
    keepCaptionPlaceholder = false,
    controlled,
    autoRotationEnabled,
    autoRotationSpeed = 7000,
    showCarouselDots = false,
    isLoadingCarouselDots = false,
    showArrows: showArrowsProp = false,
    handleDotClick,
    handleThumbnailClick,
    handleSeeMoreClick,
    onImageChange,
    onSlide,
    leftArrowImage,
    rightArrowImage,
    slideToImage,
  } = props;
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    pos: selectedThumbnailIndex || 0,
  });
  const justMounted = useRef(true);
  const carouselWithLabel = useLabel('label_carousel_with');
  const itemsLabel = useLabel('label_items');
  const slideLabel = useLabel('label_slide');
  const ofLabel = useLabel('label_of');
  const showArrows = showArrowsProp || items.length > 1;
  const { sliding, dir, pos: statePos, updateType } = state;
  const pos =
    controlled && typeof selectedThumbnailIndex !== 'undefined' ? selectedThumbnailIndex : statePos;
  const numItems = React.Children.count(children);
  const reactChildren = React.Children.toArray(children);
  const childrenLength = reactChildren.length;
  const lastItem = reactChildren.pop();
  if (lastItem) {
    reactChildren.unshift(lastItem);
  }
  const previousStatePos = usePrevious(statePos);
  const intervalRef = useRef<NodeJS.Timeout>();

  const dispatchStopSliding = () => {
    setTimeout(() => {
      dispatch({
        type: 'stopSliding',
      });
    }, 50);
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    const selectedThumbnail = items[statePos] || {};

    if (justMounted.current) {
      justMounted.current = false;
    } else if (updateType !== 'updatePos' && onImageChange) {
      onImageChange(statePos, selectedThumbnail, previousStatePos);
      controlled &&
        updateType !== 'slideToIndex' &&
        dispatch({
          type: 'startSliding',
        });
      controlled && dispatchStopSliding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePos]);

  useEffect(() => {
    if (controlled && typeof selectedThumbnailIndex !== 'undefined') {
      dispatch({
        type: 'updatePos',
        index: selectedThumbnailIndex,
      });
    }
  }, [controlled, selectedThumbnailIndex]);

  useEffect(() => {
    resetInterval();
    if (autoRotationEnabled) {
      intervalRef.current = setInterval(() => {
        slide(NEXT);
      }, autoRotationSpeed);
    }
    return () => {
      resetInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRotationEnabled, onImageChange]);

  useEffect(() => {
    if (slideToImage === 'PREV') {
      slide(PREV);
    } else if (slideToImage === 'NEXT') {
      slide(NEXT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideToImage]);

  const slide = (dir: Direction, data?: SwipeEventData) => {
    onSlide?.(dir);

    if (!data || data.velocity > 0.08) {
      dispatch({
        type: dir,
        numItems,
        controlled,
      });

      if (!controlled) {
        dispatchStopSliding();
      }
    }
  };

  const slideToIndex = (index: number) => {
    dispatch({
      type: 'slideToIndex',
      index,
      numItems,
    });
  };

  const handleCarouselDotClick = (index: number, setFocusOnAnchor: boolean) => {
    handleDotClick?.(index, setFocusOnAnchor);
    slideToIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: (data) => {
      slide(NEXT, data);
    },
    onSwipedRight: (data) => {
      slide(PREV, data);
    },
    preventDefaultTouchmoveEvent: true,
  });

  const renderLeftControl = () => (
    <Button
      customClass={cx(styles.carouselControl, classes.carouselControl, styles.carouselControlLeft)}
      onClick={() => slide(PREV)}
    >
      <div className={styles.controlImage}>
        <Image
          className={styles.header_arrow}
          src={leftArrowImage ?? sliderControls.leftArrow.src}
          alt={sliderControls.leftArrow.alt}
          width={15}
          height={27}
          loading="lazy"
        />
      </div>
    </Button>
  );

  const renderRightControl = () => (
    <Button
      customClass={cx(styles.carouselControl, classes.carouselControl, styles.carouselControlRight)}
      onClick={() => slide(NEXT)}
    >
      <div className={styles.controlImage}>
        <Image
          className={styles.header_arrow}
          src={rightArrowImage ?? sliderControls.rightArrow.src}
          alt={sliderControls.rightArrow.alt}
          width={15}
          height={27}
          loading="lazy"
        />
      </div>
    </Button>
  );

  return (
    <section
      className={cx({
        [classes.root || '']: !!classes.root,
      })}
      aria-label={`${carouselWithLabel} ${childrenLength} ${itemsLabel}`}
    >
      <div className={styles.carouselAndCaptionContainer}>
        <div
          className={cx(styles.carouselContainer, classes.customCarouselContainer)}
          {...handlers}
        >
          {!!showArrows && renderLeftControl()}
          <div
            className={cx(styles.carouselImageContainer, {
              [classes.carouselItemContainer || '']: !!classes.carouselItemContainer,
            })}
          >
            <div
              className={cx(styles.carouselSlidesContainer, {
                [styles.carouselContainerPrev]: dir === 'PREV' && sliding,
                [styles.carouselContainerSliding]: sliding,
                [styles.oneChild]: childrenLength === 1,
              })}
              id="carousel-slides-container"
            >
              {reactChildren.map((child, index) => {
                const slideIndex = index === 0 ? childrenLength : index;
                return (
                  <div
                    aria-label={`${slideLabel} ${slideIndex} ${ofLabel} ${childrenLength}`}
                    role="group"
                    key={index}
                    className={styles.carouselItem}
                    style={{
                      order: getOrder(index, pos, numItems),
                    }}
                  >
                    {child}
                  </div>
                );
              })}
            </div>
          </div>
          {!!showArrows && renderRightControl()}
          {showCarouselDots ? (
            <CarouselDots
              numberOfDots={childrenLength}
              currentSlide={pos}
              handleDotClick={handleCarouselDotClick}
            />
          ) : isLoadingCarouselDots && childrenLength > 1 ? (
            <div
              data-testid="carousel-dots-loading-container"
              className={styles.carouselDotsCenter}
            >
              <ul className={styles.carouselDotsList}>
                <LoadingIndicatorDots color="#f26100" size={14} />
              </ul>
            </div>
          ) : null}
        </div>
        {carouselCaption ? (
          <div
            className={cx(
              azCommonStyles['az-caption'],
              azCommonStyles['az-padding-top-xxxs'],
              azCommonStyles['az-padding-bottom-xxxs'],
              styles.carouselCaption,
              {
                [classes.caption || '']: classes.caption,
              }
            )}
          >
            {carouselCaption}
          </div>
        ) : (
          keepCaptionPlaceholder && <div className={styles.captionSpacer} />
        )}
      </div>
      {items.length > 0 && (
        <ThumbnailComponent
          imgTitle={imgTitle}
          classes={thumbnailClasses}
          items={items}
          onImageChange={slideToIndex}
          snapToThumbnails
          selectedThumbnailIndex={pos}
          showAllThumbnails={showAllThumbnails}
          groupedLayout={groupedLayout}
          verticalLayout={verticalLayout}
          handleSeeMoreClick={handleSeeMoreClick}
          handleThumbnailClick={handleThumbnailClick}
        />
      )}
    </section>
  );
};

function reducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case 'reset':
      return initialState;

    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: !action.controlled,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
        updateType: PREV,
      };

    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: !action.controlled,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
        updateType: NEXT,
      };

    case 'slideToIndex':
      return {
        ...state,
        pos: action.index,
        updateType: 'slideToIndex',
      };

    case 'updatePos':
      return { ...state, pos: action.index, updateType: 'updatePos' };

    case 'startSliding':
      return { ...state, sliding: true, updateType: 'startSliding' };

    case 'stopSliding':
      return { ...state, sliding: false, updateType: 'stopSliding' };

    default:
      return state;
  }
}

export default Carousel;
