/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Image from '@/components/NextImage';
import { useLabel } from '@/hooks/useLabels';
import ThreeSixtyIcon from '@/public/images/360_Icon.svg';
import PlayArrowIcon from '@/public/images/play-arrow.svg';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '../../Skeleton/Skeleton';
import { MAX_VISIBLE_THUMBNAILS } from '../constants';
import styles from './styles.module.scss';
import ThumbnailOverlay from './ThumbnailOverlay';
import type { CarouselItem, CarouselItemTypes, ThumbnailProps } from './types';
import { defaultVideoThumbnail } from '@/constants/images/defaultVideoThumbnail';

const ThumbnailComponent = (props: ThumbnailProps) => {
  const {
    classes = {},
    verticalLayout,
    handleSeeMoreClick,
    handleThumbnailClick,
    groupedLayout,
    showAllThumbnails,
    selectedThumbnailIndex,
    snapToThumbnails,
    hoverEnabled,
    loading,
    onImageChange,
    items = [],
  } = props;
  const seeMoreLabel = useLabel('hyperlink_label_shelf_See_More');
  const imagesHeader = useLabel('label_Thumbnails_header_Images');
  const videoHeader = useLabel('label_Thumbnails_header_Videos');
  const label_thumbnail_image = useLabel('label_thumbnail_image');
  const thumbnailListRef = useRef({});
  const thumbnailRefs = useRef({});
  const justMounted = useRef(true);
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const { thumbnailGroup: thumbnailGroupClass = '', thumbnailList: thumbnailListClass } = classes;
  const thumbnails = items;
  const totalThumbnailLength = thumbnails.length;

  const getThumbnailsByType = (type: CarouselItemTypes): Array<CarouselItem> => {
    return thumbnails.filter((thumbnail: CarouselItem) => thumbnail.type === type);
  };

  const imageThumbnails = getThumbnailsByType('image');
  const getTrimmedThumbnails: () => Array<CarouselItem> = () => {
    return verticalLayout && !showAllThumbnails
      ? thumbnails.slice(0, MAX_VISIBLE_THUMBNAILS)
      : thumbnails;
  };

  const showExpandedImg = (carouselItem: CarouselItem) => {
    const { enlargedMediaUrl } = carouselItem;
    const index = thumbnails.findIndex((img) => img.enlargedMediaUrl === enlargedMediaUrl);

    if (onImageChange) {
      onImageChange(index, carouselItem);
    }

    setSelectedThumbnail(index);
  };

  const handleClick = (thumbnailItem: CarouselItem) => {
    showExpandedImg(thumbnailItem);
    handleThumbnailClick?.(thumbnailItem);
  };

  useEffect(() => {
    if (
      !!thumbnailRefs &&
      !!thumbnailListRef &&
      snapToThumbnails &&
      selectedThumbnailIndex !== undefined
    ) {
      // @ts-expect-error fix type
      const thumbnailRef = thumbnailRefs.current[selectedThumbnailIndex] || {};
      const listRef: { scroll?: (scrollObj: any) => void } = thumbnailListRef.current || {};
      const thumbnailOffsetLeft = thumbnailRef.offsetLeft;
      const thumbnailWidth = thumbnailRef.offsetWidth;
      // @ts-expect-error fix type
      const thumbnailListCenter = listRef.offsetWidth / 2;
      const thumbnailFromLeftDistance = thumbnailOffsetLeft + thumbnailWidth / 2;
      const toCenter = thumbnailFromLeftDistance - thumbnailListCenter;
      const scrollBehavior = justMounted.current ? 'auto' : 'smooth';
      if (listRef.scroll) {
        listRef.scroll({
          left: toCenter,
          top: 0,
          behavior: scrollBehavior,
        });
      }
      justMounted.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThumbnailIndex]);
  const thumbnailStyle = verticalLayout
    ? styles.thumbnailBox
    : `${styles.thumbnailBox} ${styles.inlineThumbnailBox}`;
  const thumbnailContainerStyle = verticalLayout
    ? styles.thumbnailsContainer
    : `${styles.thumbnailsContainer} ${styles.thumbnailsContainerHorizontal}`;
  const shouldShowSeeMore =
    verticalLayout && !showAllThumbnails && totalThumbnailLength >= MAX_VISIBLE_THUMBNAILS;

  const renderThumbnail = (thumbnailItem: CarouselItem, thumbIndex: number) => {
    const { type, thumbnail } = thumbnailItem;
    const isVideo = type === 'video';
    const is360 = type === 'spin360';
    const isLastVisible = thumbIndex + 1 === MAX_VISIBLE_THUMBNAILS;
    const isSeeMoreButton = isLastVisible && shouldShowSeeMore;
    const actualThumbnailIndex =
      isVideo && groupedLayout ? thumbIndex + imageThumbnails.length : thumbIndex;
    const isInvalidVideoThumbnail = isVideo && (!thumbnail || thumbnail === 'null');

    const onMouseEnter =
      hoverEnabled && !isSeeMoreButton
        ? () => {
            showExpandedImg(thumbnailItem);
          }
        : () => {};
    const onClick = isSeeMoreButton
      ? () => handleSeeMoreClick?.()
      : () => handleClick(thumbnailItem);
    return (
      <li
        // @ts-expect-error fix type
        ref={(element) => (thumbnailRefs.current[actualThumbnailIndex] = element)}
        className={thumbnailStyle}
        key={`${thumbnail}-${thumbIndex}`}
      >
        <button
          className={cx(styles.thumbnailButton, {
            [styles.thumbnailButtonVideo]: isVideo,
            [styles.thumbnailButtonSelected]:
              !isSeeMoreButton &&
              (selectedThumbnailIndex !== undefined
                ? selectedThumbnailIndex === actualThumbnailIndex
                : selectedThumbnail === actualThumbnailIndex),
          })}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
        >
          <>
            <Image
              src={isInvalidVideoThumbnail ? defaultVideoThumbnail.src : thumbnail}
              alt=""
              data-testid={`thumbnail-image-${thumbIndex}`}
              className={styles.thumbnailImage}
              width={70}
              height={70}
              itemProp="image"
              loading="lazy"
            />
            {(isVideo || is360) && !isSeeMoreButton && (
              <ThumbnailOverlay image={isVideo ? PlayArrowIcon : ThreeSixtyIcon} />
            )}
            {isSeeMoreButton && <ThumbnailOverlay text={seeMoreLabel} />}
          </>
          <span className="sr-only"> {`${label_thumbnail_image} ${thumbIndex + 1}`}</span>
        </button>
      </li>
    );
  };

  const renderThumbnailList = (thumbnails: Array<CarouselItem>) => {
    return (
      <ul
        // @ts-expect-error fix type
        ref={thumbnailListRef}
        className={cx(thumbnailContainerStyle, thumbnailListClass, {
          [classes.thumbnailContainer || '']: classes.thumbnailContainer,
        })}
        data-testid="thumbnails-container"
      >
        {!loading && thumbnails?.map(renderThumbnail)}
        {loading &&
          thumbnails?.map((thumbnail, index) => (
            <li key={index} className={thumbnailStyle}>
              <Skeleton
                animation="wave"
                height={70}
                width={70}
                variant="rect"
                data-testid="skeleton-loader"
              />
            </li>
          ))}
      </ul>
    );
  };

  const renderThumbnailGroup = (thumbnails: Array<CarouselItem> = [], header: string) => {
    return (
      <div className={styles.thumbnailGroup}>
        {!!header && !!thumbnails.length && (
          <div className={cx(styles.thumbnailHeading, azCommonStyles['az-title-5-medium'])}>
            {header}
          </div>
        )}
        {renderThumbnailList(thumbnails)}
      </div>
    );
  };

  if (groupedLayout) {
    const videoThumbnails = getThumbnailsByType('video');
    return (
      <div className={thumbnailGroupClass}>
        {renderThumbnailGroup(imageThumbnails, imagesHeader)}
        {renderThumbnailGroup(videoThumbnails, videoHeader)}
      </div>
    );
  }

  return renderThumbnailList(getTrimmedThumbnails());
};

export default ThumbnailComponent;
