/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import cx from 'classnames';
import iconStyles from '@/theme/iconStyles.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';

type Props = {
  image?: React.ElementType;
  text?: string;
};

const ThumbnailOverlay = ({ image: Image, text }: Props) => {
  return (
    <div className={cx(styles.thumbnailOverlay, { [azCommonStyles['az-title-5-heavy']]: text })}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      {!!Image && <Image className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeLarge)} />}
      {!!text && text}
    </div>
  );
};

export default ThumbnailOverlay;
