/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { siteSections } from '@/utils/siteSections';
import { pageTypes } from './pageTypes';

const {
  siteSectionMyzone,
  siteSectionGiftCard,
  siteSectionTrackOrder,
  siteSectionPageUnavailable,
} = siteSections;

export const getPageName = (pageType: string, appendedPageName: string): string => {
  switch (pageType) {
    case pageTypes.brandShelfPremium:
      return `az:catalog:${appendedPageName}:Premium-Brand-Profile`;
    case pageTypes.brandShelf:
      return `az:catalog:${appendedPageName}:Basic-Brand-Profile`;
    case pageTypes.home:
      return `az:${pageTypes.home}`;
    case pageTypes.landingPage:
      return `az:${pageTypes.landingPage}:${appendedPageName}`;
    case pageTypes.shopByMake:
      return 'az:catalog:shopbymake';
    case pageTypes.shopByModel:
      return 'az:catalog:shopbymodel';
    case pageTypes.pdp:
    case pageTypes.productDetails:
      return `az:${appendedPageName}`;
    case pageTypes.catalog:
      return `az:${pageTypes.catalog}:${appendedPageName}`;
    case pageTypes.catalogSearchResults:
      return 'az:catalog:search-results';
    case pageTypes.catalogNewSearchResults:
      return 'az:catalog:Search Results Shelf';
    case pageTypes.productShelf:
      return `az:catalog:${appendedPageName}`;
    case pageTypes.contactUs:
      return appendedPageName.length
        ? `az:customer-service:contact-us:${appendedPageName}`
        : `az:customer-service:contact-us`;
    case pageTypes.createAccount:
      return 'az:myzone:create';
    case pageTypes.myAccount:
      return `az:${siteSectionMyzone}:${appendedPageName}`;
    case pageTypes.giftCard:
      return `az:${siteSectionGiftCard}:${appendedPageName}`;
    case pageTypes.trackOrder:
      return `az:${siteSectionTrackOrder}:${appendedPageName}`;
    case pageTypes.cart:
      return 'az:cart:full-cart';
    case pageTypes.orderConfirmation:
      return `az:${pageTypes.orderConfirmation}`;
    case pageTypes.orderHistory:
      return `az:${siteSectionMyzone}:order-history`;
    case pageTypes.orderHistoryDetails:
      return `az:${siteSectionMyzone}:order-details`;
    case siteSectionPageUnavailable:
      return `az:${siteSectionPageUnavailable}:${appendedPageName}`;
    case pageTypes.sms:
      return `az:${pageTypes.sms}`;
    case pageTypes.resetPassword:
      return `az:${siteSectionMyzone}:${pageTypes.resetPassword}`;
    case pageTypes.signIn:
      return `az:${pageTypes.signIn}`;
    case pageTypes.requestCredit:
      return `az:${siteSectionMyzone}:${appendedPageName}`;
    case pageTypes.myOrders:
      return `az:${siteSectionMyzone}:order-history:orders`;
    case pageTypes.brandsMain:
      return `az:${pageTypes.brandsMain}`;
    case pageTypes.reviews:
      return `az:reviews:${appendedPageName}`;
    default: {
      if (
        appendedPageName in siteSections &&
        siteSections[appendedPageName as keyof typeof siteSections] !== undefined
      ) {
        return `az:${
          siteSections[appendedPageName as keyof typeof siteSections]
        }:${appendedPageName}`;
      }

      return '';
    }
  }
};
